// View Actions
export const SET_HOVER = 'SET_HOVER';

export const FOCUS_ELEMENT = 'FOCUS_ELEMENT';
export const UNFOCUS_ELEMENTS = 'UNFOCUS_ELEMENTS';

export const SET_USER_UPLOAD = 'SET_USER_UPLOAD';
export const START_TRANSITION = 'START_TRANSITION';
export const PERSIST_TRANSITION = 'PERSIST_TRANSITION';
export const COMPLETE_TRANSITION = 'COMPLETE_TRANSITION';

export const TOGGLE_DELETION = 'TOGGLE_DELETION';
export const PREP_FOR_DELETION = 'PREP_FOR_DELETION';
export const RESET_DELETION = 'RESET_DELETION';

export const TOGGLE_SWAP = 'TOGGLE_SWAP';
export const PREP_FOR_SWAP = 'PREP_FOR_SWAP';
export const RESET_SWAP = 'RESET_SWAP';

export const TOGGLE_DOWNLOAD = 'TOGGLE_DOWNLOAD';

export const TOGGLE_CONTROLS = 'TOGGLE_CONTROLS';

export const SET_EDITOR_STATE = 'SET_EDITOR_STATE';

// Stack Actions
export const UPDATE_UNIT_PADDING = 'UPDATE_UNIT_PADDING';
export const UPDATE_LAYER_PADDING = 'UPDATE_LAYER_PADDING';
export const REFOCUS_STACK = 'REFOCUS_STACK';

export const SET_CUSTOM_USER_ARRAY = 'SET_CUSTOM_USER_ARRAY';
export const SET_RAW_USER_ARRAY = 'SET_RAW_USER_ARRAY';

export const EXPAND_STACK = 'EXPAND_STACK';
export const COLLAPSE_STACK = 'COLLAPSE_STACK';

export const ADD_TO_STACK = 'ADD_TO_STACK';
export const REMOVE_FROM_STACK = 'REMOVE_FROM_STACK';
export const SWAP_STACK = 'SWAP_STACK';

export const CLEAR_STACK = 'CLEAR_STACK';
export const INITIALISE_STACK = 'INITIALISE_STACK';